@import "src/styles/variables";

.department-details {

  .ant-modal-content,
  .ant-modal-body {
    padding: 0 !important;
  }

  .department-details__basic-info {
    position: relative;
    padding: 50px;
    background: url("../../../../../assets/header3.png") no-repeat center;
    background-size: cover;
    color: #FFFFFF;

    .department-details__name {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .hospital-details__bio {
      color: #D4D4D4;
      margin-bottom: 28px;
      span,
      p {
        word-break: break-all;
        margin-bottom: 10px;
      }
      pre{
        margin-bottom: 10px;

      }
      .text-overflow {
        height: 40px;
      }
    }

    .hospital-details__communication {
      margin-top: 30px;

      .hospital-details__contact {
        display: inline-block;
        margin-right: 25px;

        .hospital-details__contact-icon {
          background: #FFFFFF09;
          padding: 7px 10px;
          border-radius: 50%;
          margin-right: 8px;

          i:before {
            color: #FFFFFF;
          }
        }
      }
    }

    .department-details__edit {
      position: absolute;
      display: inline;
      background: #FFFFFF;
      border-radius: 50%;
      bottom: 12px;
      right: 15px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;

      i:before {
        color: #000;
      }
    }
  }

  .department-details__staffs {
    position: relative;
    width: 90%;
    margin: 30px auto;

    .department-details__staff {
      display: inline-block;
      vertical-align: top;
      width: 32%;
      margin-right: 2%;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #EEEEEE;
      border-radius: 15px;
      padding: 15px 16px;
      margin-bottom: 20px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .department-details__staff__image,
      .department-details__staff__details {
        display: inline-block;
        vertical-align: middle;
      }

      .department-details__staff__image {
        width: 20%;
        margin-right: 13px;

        img {
          width: 100%;
          border-radius: 50%;
        }
      }

      .department-details__staff__name {
        font-weight: 500;
        color: #000000;
        font-size: 15px;
      }

      .department-details__staff__speciality {
        font-size: 13px;
        color: #646464;
      }
    }
  }
}