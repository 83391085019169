@import "src/styles/variables";

.staffs {
  width: 100%;
  margin: 15px auto;
  padding:5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  img{
    width: 30px;
    height: 30px;
    margin-right: 8px;
    border-radius: 50%;
    object-fit: cover;
  }

  .ant-table-tbody > tr > td:first-child {
    width: 300px !important;
  }

  .ant-table{
    .staff-specialization{
      width: 175px !important;
    }
  }
}