@import "src/styles/variables";

.hospital-details {
  .hospital-details__basic-info {
    background: url("../../../assets/header2.png") no-repeat;
    background-size: cover;
    border-radius: 12px;
    background-position-x: 10%;
    color: #FFFFFF;
    padding: 30px 40px;
    position: relative;

    .hospital-details__navigation {

      .go-back,
      .hospital-details__name,
      .hospital-details__button {
        color: #FFFFFF;
        display: inline-block;
      }

      .go-back {
        cursor: pointer;
      }

      .hospital-details__name {
        margin-right: 15px;
        font-size: 18px;
        font-weight: 600;
      }

      .hospital-details__button {
        margin-right: 10px;
        background: #FFFFFF12 !important;
        border-radius: 9px;
        color: #FFFFFF !important;
        font-size: 14px;
        font-weight: 300 !important;
      }
    }

    .hospital-details__bio {
      margin-top: 12px;
      color: #D4D4D4;
      font-size: 15px;
      margin-left: 35px;
      h1, h2, h3, h4, h5, h6{
        color: unset;
      }
      span,
      p {
        word-break: break-all;
        margin-bottom: 10px;
      }
      pre{
        margin-bottom: 10px;

      }
      .text-overflow {
        height: 40px;
      }
    }

    .hospital-details__website {
      margin-left: 35px;
      margin-top: 20px;
    }

    .hospital-details__edit {
      position: absolute;
      display: inline;
      background: #FFFFFF;
      border-radius: 50%;
      bottom: 12px;
      right: 15px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;

      i:before {
        color: #000;
      }
    }
  }

  .hospital-details__staffs {
    width: 95%;
    margin: 20px auto;
  }
}

.hospital-details__communication {
  margin-left: 35px;
  margin-top: 30px;

  .hospital-details__contact {
    display: inline-block;
    margin-right: 25px;

    .hospital-details__contact-icon {
      background: #FFFFFF07;
      padding: 7px 10px;
      border-radius: 50%;
      margin-right: 8px;

      i:before {
        color: #FFFFFF;
      }
    }
  }
}