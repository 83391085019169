.auth-form,.auth-image {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  //animation: fadeIn 1s linear ;
}

.auth-form{
  width: 41.5%;
  margin-top: 20vh;
  padding: 0 1% 0 9%;
}

@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.auth-image{
  width: 58.5%;
  height: 100vh;
  background: url("../../assets/Landing.png") no-repeat;
  background-size: cover;
  text-align: center;
  background-position-y: center;
  .auth-image__logo{
      margin-top: 33vh;
      width: 30%;
  }
}

.auth-form__header{
  font-size: 42px;
  font-weight: 600;
  color: #272363;
}

.auth-form__field{
  margin: 18px 0 0 0;
  .input-field{
    width: 80%;
  }
}

.form-label{
  color: #888585;
  font-size: 14px;
  margin-bottom: 5px;
}

.auth-form__forgot{
  margin-top: 10px;
  color: #535353;
  font-size: 14px;
  text-align: right;
  width: 80%;
  div{
    cursor: pointer;
  }
}

.auth-form__action{
  width: 80% !important;
  margin-top: 33px;
  height: 52px !important;
  font-size: 18px !important;
  &.ant-btn-loading{
    width: 80% !important;
  }
}
