@import "src/styles/variables";

.patient-details {
  .ant-modal-content, .ant-modal-body{
    padding: 0 !important;
  }

  .patient-details__header{
    background: url("../../../assets/header.png") no-repeat center;
    background-size: cover;
    border-radius: 12px 12px 0 0;
    color: #FFFFFF;
    padding: 40px 50px 20px 50px;

    .patient-details__profile-info{
      margin-bottom: 20px;
      .patient-details__image{
        display: inline-block;
        vertical-align: middle;
        width: 15%;
        margin-right: 20px;
        img{
          width: 100%;
          border-radius: 19px;
          object-fit: cover;
        }
      }

      .patient-details__basic-info{
        display: inline-block;
        vertical-align: middle;
        width: auto;

        .patient-details__name{
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .patient-details__age{
          font-size: 16px;
          margin-bottom: 5px;
        }

        .patient-details__languages{
          .icon{
            display: inline-block;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            line-height: 30px;
            text-align: center;
            background: #FFFFFF10;
            font-size: 15px;
            &:before{
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }

  .patient-details__row{
    .patient-details__label{
      color: #888585;
      font-size: 14px;
      &.about{
        color: #D4D4D4;
      }
    }

    .patient-details__content{
      color: #4F4F4F;
      font-size: 16px;
      margin-bottom: 25px;
      &.about{
        color: #FFFFFF;
        margin-bottom: 0;
      }
      ul{
        padding-inline-start: 20px !important;
      }
    }
  }

  .patient-details__medical-info{
    padding: 25px 50px;
  }
}