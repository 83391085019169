@import "src/styles/variables";

.tag-form__header {
  font-size: 17px;
  font-weight: 700;
  color: $secondary-color;
}

.tag-form__row {
  width: 100%;
  margin-top: 20px;
}
.hospital-form__field {
    
  display: inline-block;
  vertical-align: top;
  width: 47%;
  margin-right: 6%;

  &.full-width {
    width: 100%;
    margin-bottom: 25px;
  }
}
.tag-form__actions {
  text-align: right;
  margin-top: 20px;

  .tag-form__action {
    margin-left: 20px;
    height: 52px !important;
    width: 24%;
    font-size: 16px !important;
  }
}