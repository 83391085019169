@import "src/styles/variables";

.patients__journey-list {

    .ant-collapse {
        margin: 1rem 0 !important;
        border: none !important;
        position: relative;

        .ant-collapse-item {
            margin: 0 0 1rem 0;
            width: 100%;
            position: relative;
            background: white;
            border: none !important;
            border-radius: 8px !important;
            transition: all 500ms;
            opacity: 1 !important;
            box-shadow: 0px 5px 20px #7090B029;

            &.ant-collapse-item-active {
                border-radius: 8px 8px 0 0 !important;
            }

            &:last-child>.ant-collapse-content {
                opacity: 1 !important;
                z-index: 1;
            }

            .ant-collapse-header {
                padding: 1rem 2.5rem;
                border: none;
                font-size: 18px;
                font-weight: bold;

                .ant-collapse-arrow {
                    padding: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 18px;
                    transition: all .5s;

                    &.rotate {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .ant-collapse-content {
                width: inherit;
                border: none !important;
                transition: all 300ms ease 0s !important;
                box-shadow: 0px 15px 15px #7090B029;
                border-radius: 0 0 8px 8px !important;

                .ant-collapse-content-box {
                    padding: 1rem;

                    .patient-journey-card {
                        width: 340px;
                    }
                }
            }
        }

    }
}