@import "src/styles/variables";

.departments {
  .manage-groups__details-card {
    position: relative;
    display: inline-block;
    width: 32%;
    vertical-align: top;
    margin: 30px 2% 0 0;
    color: #000000;
    padding: 25px 30px 15px 30px;
    cursor: pointer;
    border-radius: 15px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid #EEEEEE;

    &:hover {
      box-shadow: 0 5px 20px #0000000F;
    }

    &:nth-child(3n+1) {
      margin-right: 0;
    }

    .manage-groups__details--action {
      position: absolute;
      right: 15px;
      top: 15px;
      transform: rotate(90deg);
    }

    .manage-groups__details-card--name {
      position: relative;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .manage-groups__details-card--members {
      position: relative;
      margin: 10px 0;

      img {
        margin-right: 3.5%;
        width: 13%;
        object-fit: cover;
        border-radius: 50%;

        &:last-child {
          margin-right: 0;
        }
      }

      .image-overlay {
        position: absolute;
        width: 12%;
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #00000090;
        border-radius: 50%;
        color: #ffffff;

        .count {
          line-height: 2.25;
          text-align: center;
        }
      }
    }

    .manage-groups__details-card--created {
      color: #E4791C;
      text-align: right;
      font-size: 10px;
    }

    .manage-groups__details-card--location {
      margin-bottom: 15px;
      font-size: 14px;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        background: #F5F5F5;
        border-radius: 50%;
        padding: 8px;
        margin-right: 5px;

        &:before {
          color: #646464;
        }
      }
    }
  }

  .departments__pagination {
    text-align: right;
    margin-top: 30px;
  }
}