@import "src/styles/variables";

.faq-accordions {
  height: 80vh;
  overflow: auto;
  .site-collapse-custom-collapse{
    background: #FFFFFF;
    width: 99%;
    margin: auto;
    .ant-collapse-arrow{
      font-size: 20px !important;
      color: #D4D4D4 !important;
      svg {
        transform: rotate(90deg);
      }
    }

    .ant-collapse-item-active{
      .ant-collapse-arrow {
        color: $secondary-color !important;
        svg{
          transform: rotate(-90deg) !important;
        }
      }
    }

    .site-collapse-custom-panel{
      border-bottom: unset !important;
     .ant-collapse-header{
       color: #000000;
       font-size: 15px;
       font-weight: 600;
     }
    }

    .ant-collapse-item-active.site-collapse-custom-panel{
      background: #FFFFFF 0 0 no-repeat padding-box !important;
      box-shadow: 0 10px 20px #AEAEAE29 !important;
      border-radius: 8px;
      margin-top: 10px;
      .ant-collapse-header{
        font-size: 17px;
        font-weight: 700;
      }
    }

    .ant-collapse-horizontal{
      width: 96%;
      min-width: unset;
      margin: auto;
      border: 1px solid #EAEAEA !important;
    }

    .faq-accordion__answer{
      width: 85%;
      margin: auto;
      .faq-accordion__tags{
        margin-bottom: 20px;
      }

      .faq-accordion__content{
        color: #4F4F4F;
        font-size: 15px;
        ul{
          padding-inline-start: 20px !important;
        }
      }
    }

    .faq-accordion__actions{
      margin-top: 20px;
      text-align: right;
      i{
        background: #EFEFEF;
        border-radius: 4px;
        margin-right: 10px;
        padding: 8px;
        cursor: pointer;
        &:before{
          color: #000000;
        }
      }
    }
  }
}

.faq-accordion__tag{
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 7px 17px;
  border-radius: 17px;
  font-size: 13px;
  &.type-1{
    color: #1046C5;
    background: #DCE5F9;
  }
  &.type-2{
    color: #1BBF31;
    background: #DCF9E0;
  }
  &.type-3{
    color: #601ADA;
    background: #E7DCF9;
  }
}