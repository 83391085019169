@import "src/styles/variables";

.ant-btn-primary {
    background: $primary-button-gradient;
    border-radius: 4px;
    border-color: unset !important;
}

.ant-btn-text {
    color: #ADADAD !important;
    background-color: #FFFFFF !important;
}

.ant-btn-loading {
    width: auto !important;
}

.btn-icon {
    margin-right: 12px;
    font-size: 18px;
    color: #4F4F4F;
    vertical-align: middle;
}

.ant-btn.ant-btn-primary[disabled] {
    color: #FFFFFF80;

    &:hover {
        color: #FFFFFF80;
    }
}