@import "src/styles/variables";

.faq-form {
  padding-bottom: 50px !important;
  .go-back {
    cursor: pointer;
  }
}

.go-back {
  display: inline-block;
  color: $secondary-color;
  cursor: pointer;
  .anticon {
    margin-right: 20px;
  }
}

.faq-form__wrapper {
  margin-top: 27px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 10px 20px #AEAEAE29;
  border-radius: 12px;
  padding: 38px 65px;
  .faq-form__field {
    margin-bottom: 32px;
    .label {
      color: #535353;
      font-size: 13px;
      margin-bottom: 12px;
    }
    .input-field .ant-input {
      border-radius: 3px;
    }

    .ck.ck-editor__main > .ck-editor__editable {
      height: 500px;
    }
  }
  .faq-form__actions {
    .ant-btn {
      height: 42px;
      border-radius: 8px;
      font-size: 14px;
    }
  }
}

