@import "src/styles/variables";

.journey-template-checklists {
  .journey-template-checklists__item {
    margin-top: 10px;
    width: 50%;
    display: flex;
    align-items: center;

    &.extended {
      width: calc(50% + 85px + 71px);
    }

    .ant-checkbox-inner {
      width: 24px !important;
      height: 24px !important;
      border: 1px solid #E6E6E6 !important;
      border-radius: 3px !important;

      &::after {
        left: 30% !important;
      }
    }

    .ant-checkbox-wrapper,
    .journey-template-checklists__name {
      display: inline-block;
      vertical-align: top;
    }

    .ant-checkbox-wrapper {
      margin-top: 2px;
    }

    .journey-template-checklists__name {
      margin-left: 10px;
      font-size: 16px;
      color: #4F4F4F;
      width: calc(100% - 55px);
    }

    .anticon-ellipsis {
      flex-grow: 1;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    .ant-checkbox-wrapper {
      &.ant-checkbox-wrapper-checked {

        &.ant-checkbox-wrapper-disabled,
        .ant-checkbox-disabled {
          cursor: default;
        }

        &.confirmed {
          .ant-checkbox-inner {
            background: #2ea630;

            &::after {
              top: 50%;
              right: 50%;
              transform: rotate(45deg) scale(1.2) translate(-40%, -50%);
              border-color: white;
            }
          }
        }

        &.completed {
          .ant-checkbox-inner {
            background: orange;

            &::after {
              top: 50%;
              right: 50%;
              transform: rotate(45deg) scale(1.2) translate(-40%, -50%);
              border-color: white;
            }
          }
        }

      }
    }

    .ant-btn {

      &.approve {
        color: #2EA67E !important;
      }

      &.reject {
        color: #FF5C5C !important;
      }

    }
  }
}