@import "src/styles/variables";

.journey-event-details {
  height: 55vh;
  overflow-x: scroll;
  .journey-event-details__row{
    margin-top: 25px;
    .journey-event-details__label{
      color: #888585;
      margin-bottom: 8px;
    }
    .journey-template-step__label,.journey-event-details__label{
      font-size: 15px;
    }
    .journey-event-details__content{
      font-size: 18px;
      color: #4F4F4F;
    }
    .journey-event-details__column{
      display: inline-block;
      width: 40%;
      margin-right: 5%;
      vertical-align: top;
      .journey-template-members__image{
        width: 55px;
        height: 55px;
        border-radius: 50%;
        margin-left: 10px;
      }
    }

    .journey-event-details__activity{
      .journey-event-details__activity-image,.journey-event-details__activity-name{
        display: inline-block;
        vertical-align: middle;
      }
      .journey-event-details__activity-image{
        width: 50px;
        img{
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .journey-event-details__activity-name{
        font-weight: bold;
        font-size: 16px;
        .journey-event-details__activity-time{
          font-size: 12px;
          color: #888585;
          font-weight: normal;
          margin-left: 5px;
        }
      }
      .journey-event-details__activity-info{
        margin-left: 50px;
        font-size: 15px;
        color:#4F4F4F;
      }
    }
  }
}