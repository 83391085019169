@import "src/styles/variables";

.article-form {
  padding-bottom: 50px !important;
  min-height: 100vh;

  .article-form__wrapper {
    margin-top: 27px;
  }

  .faq-form__wrapper {
    margin-top: unset;
    border-radius: 0 0 12px 12px;
  }

  .article-form__banner-image {
    position: relative;
    background: #F2F2F2;
    border-radius: 4px 4px 0 0;
    height: 400px;
    text-align: center;

    .article-form__image-upload {
      position: absolute;
      top: 0;
      left: 0;
      background: red;
      width: 100%;
      height: 400px;
      opacity: 0;
      cursor: pointer;
    }

    .article-form__banner-image__placeholder {
      padding-top: 115px;

      .icon-image-placeholder {
        font-size: 110px;

        &:before {
          color: #D4D4D4;
        }
      }

      .article-form__banner-image-label {
        color: #888585;
        font-size: 14px;
        margin-top: 10px;

        i {
          margin-right: 10px;
        }
      }
    }

    .article-form__banner-image__view {
      .article-form__banner-image__action {
        position: absolute;
        z-index: 2;
        right: 10px;
        bottom: 10px;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 7px 11px;
        font-size: 12px;
        color: #000000;
        cursor: pointer;

        i {
          margin-right: 5px;
          font-size: 20px;
          vertical-align: bottom;

          &:before {
            color: #000000;
          }
        }

        span {
          cursor: pointer;
          margin-left: 10px;
        }

        .article-form__banner-image-change {
          position: absolute;
          width: 50%;
          left: 0;
          cursor: pointer;
          opacity: 0;
        }
      }

      .uploaded-image {
        position: relative;
        border-radius: 4px 4px 0 0 !important;
        width: 100%;
        height: 400px;
        object-fit: cover;
        z-index: 1;
      }
    }
  }
}