@import "src/styles/variables";

.faq-sidebar {
  height: 80vh;
  overflow: auto;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 10px 20px #AEAEAE29;
  padding: 30px 20px;
  border-radius: 8px;
  position: relative;

  .faq-sidebar__title {
    position: relative;

    .faq-sidebar__active-background {
      display: block;
      z-index: 1;
      background: #234763;
      border-radius: 4px;
      height: 40px;
      position: absolute;
      top: 0;
      left: 5%;
      width: 0;

      &.active {
        width: 90%;
      }
    }

    .faq-title {
      position: relative;
      color: #646464;
      font-size: 15px;
      padding: 10px 20px;
      width: 90%;
      margin-left: 5%;
      cursor: pointer;
      text-align: left;
      z-index: 2;

      &.active {
        color: #FFFFFF;
        background: #234763;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        &:first-child {
          width: 80%;
        }
        &:last-child {
          text-align: right;
          width: 20%;
        }
      }
    }

    .ant-divider {
      width: 90%;
      min-width: unset;
      margin: 15px auto;
    }

    &:hover:not(.add-new) {
      .faq-sidebar__active-background {
        width: 90%;
        animation: animateWidth 350ms ease-in;
      }

      .faq-title {
        background: #234763;
        color: #FFFFFF;
      }
    }

    &.add-new {
      .faq-title {
        font-size: 18px;
        color: #235663;
        margin-top: 10px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}

@keyframes animateWidth {
  0% {
    width: 0;
  }

  100% {
    width: 90%;
  }
}