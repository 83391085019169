@import "src/styles/variables";

.journey-template-step-details {
  position: relative;
  display: block;
  width: 100%;

  .journey-template-step__name{
    font-size: 18px;
    font-weight: 800;
    color: #000;
  }

  .journey-template-step__content{
    margin-top: 20px;
    .journey-template-step__value{
      font-size: 16px;
      color: #4F4F4F;
    }
  }
}

.journey-template-step__label{
  font-size: 14px;
  color: #888585;
  margin-bottom: 5px;
}