@import "src/styles/variables";

.journey-categories {
    height: 100%;
    position: relative;

    .journey-categories__add.ant-btn.ant-btn-link {
        color: $primary-color;
        font-size: 18px;
        font-weight: bold;
        padding: 10px 35px;
        width: 100%;
        left: 0;
        height: auto;
        text-align: left;
        background-color: #FFF;
    }

    .ant-collapse {
        &.ant-collapse-borderless {
            background-color: transparent;
        }

        &.active {

            .ant-collapse-header {
                background-color: #F1F0F8;

                .ant-input {
                    background-color: #F1F0F8;
                }
            }
        }

        >.ant-collapse-item {
            background-color: #FFF;
            padding: 0;
            border: none;


            .ant-collapse-content>.ant-collapse-content-box {
                padding: 0;
            }

            >.ant-collapse-header {
                padding: .5rem 15px .5rem 52px;
                font-size: 18px;

                .ant-collapse-arrow {
                    padding: 0;
                    left: 35px;
                    top: 1rem;
                    // transform: translateY(-50%);
                }
            }
        }

        &.editable {
            .ant-collapse-header {
                .anticon.anticon-up {
                    top: 1rem;
                }
            }
        }
    }

    .journey-categories__category-label {
        display: flex;
        align-items: center;

        .journey-categories__category-title,
        .journey-categories__add-icon,
        .journey-categories__ellipsis-icon {
            display: inline-block;
            vertical-align: middle;
        }

        .journey-categories__category-title {
            // width: calc(100% - 55px);
            font-size: 16px;
            flex-grow: 1;
            font-weight: bold;

            .ant-input.journey-categories__category-title-input {
                border: none;
                padding: 2px 5px;
                border-bottom: 1px solid transparent;
                border-radius: 0;
                font-size: 16px;
                font-weight: bold;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &:hover {
                    border-bottom: 1px solid #bbb;
                }
            }
        }

        .journey-categories__add-icon {
            width: 20px;
            font-size: 25px;
            font-weight: bold;
            cursor: pointer;
            padding: .5rem;
        }

        .journey-categories__ellipsis-icon {
            width: 20px;
            margin-left: 5px;
            margin-top: 2px;

            svg {
                color: #B4B4B4;
            }
        }
    }
}

.journey-categories__background-shadow {
    box-shadow: 0px 3px 15px #00000015;
}