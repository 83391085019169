@font-face {
  font-family: 'icomoon';
  src:  url('../assets/JOS-icons/fonts/icomoon.eot?hqbb5n');
  src:  url('../assets/JOS-icons/fonts/icomoon.eot?hqbb5n#iefix') format('embedded-opentype'),
  url('../assets/JOS-icons/fonts/icomoon.ttf?hqbb5n') format('truetype'),
  url('../assets/JOS-icons/fonts/icomoon.woff?hqbb5n') format('woff'),
  url('../assets/JOS-icons/fonts/icomoon.svg?hqbb5n#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accepted:before {
  content: "\e900";
  color: #646464;
}
.icon-articles:before {
  content: "\e901";
  color: #646464;
}
.icon-attachments:before {
  content: "\e902";
  color: #646464;
}
.icon-camera:before {
  content: "\e903";
  color: #646464;
}
.icon-carousal:before {
  content: "\e904";
  color: #646464;
}
.icon-dashboard:before {
  content: "\e905";
  color: #646464;
}
.icon-date:before {
  content: "\e906";
  color: #646464;
}
.icon-delete:before {
  content: "\e907";
  color: #646464;
}
.icon-doctor-notes:before {
  content: "\e908";
  color: #646464;
}
.icon-edit-1:before {
  content: "\e909";
  color: #646464;
}
.icon-edit:before {
  content: "\e90a";
  color: #646464;
}
.icon-email:before {
  content: "\e90b";
  color: #646464;
}
.icon-employees:before {
  content: "\e90c";
  color: #646464;
}
.icon-event-details:before {
  content: "\e90d";
  color: #646464;
}
.icon-faq:before {
  content: "\e90e";
  color: #646464;
}
.icon-hospitals:before {
  content: "\e90f";
  color: #646464;
}
.icon-image-placeholder:before {
  content: "\e910";
  color: #646464;
}
.icon-journey-template:before {
  content: "\e911";
  color: #646464;
}
.icon-journey:before {
  content: "\e912";
  color: #646464;
}
.icon-language:before {
  content: "\e913";
  color: #646464;
}
.icon-location:before {
  content: "\e914";
  color: #646464;
}
.icon-log-out:before {
  content: "\e915";
  color: #646464;
}
.icon-members:before {
  content: "\e916";
  color: #646464;
}
.icon-mobile:before {
  content: "\e917";
  color: #646464;
}
.icon-my-account:before {
  content: "\e918";
  color: #646464;
}
.icon-on-going:before {
  content: "\e919";
  color: #646464;
}
.icon-on-hold:before {
  content: "\e91a";
  color: #646464;
}
.icon-pending:before {
  content: "\e91b";
  color: #646464;
}
.icon-rejected:before {
  content: "\e91c";
  color: #646464;
}
.icon-support:before {
  content: "\e91d";
  color: #646464;
}
.icon-surgery:before {
  content: "\e91e";
  color: #646464;
}
.icon-upload:before {
  content: "\e91f";
  color: #646464;
}
