@import "src/styles/variables";

.article-comments {
  background: #F8F8FB;
  padding: 25px 30px;
  border-radius: 4px;

  .article-comments__title{
    font-weight: 700;
    font-size: 18px;
    color: #000000;
  }

  .article-comments__list{
    max-height: 300px;
    overflow: scroll;
    margin-bottom: 20px;
    .article-comment{
      margin-top: 20px;

      .article-comment__header{
        .article-comment__user-image{
          display: inline-block;
          vertical-align: middle;
          width: 55px;
          img{
            width: 42px;
            height: 42px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .article-comment__user-name{
          display: inline-block;
          vertical-align: middle;
          color: #535353;
          font-weight: 700;
          font-size: 14px;
        }
        .article-comment__time{
          display: inline-block;
          vertical-align: middle;
          color: #888585;
          font-size: 12px;
          margin-left: 12px;
        }
      }

      .article-comment__content{
        margin: 0 0 20px 55px;
        color: #4f4f4f;
        font-size: 14px;
      }
    }
  }

  .article-comment__new-comment{
    position: relative;
    .article-comment__current-user-image{
      display: inline-block;
      vertical-align: top;
      width: 55px;
      img{
        width: 45px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .article-comment__input{
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 55px);
      .ant-input{
        border-radius: 5px !important;
        resize: none;
        border: 1px solid #D4D4D4;
        padding: 10px 0 0 15px;
        &:hover{
          border-color: #D4D4D4;
        }
        &:focus{
          box-shadow: unset;
        }
      }
      .ant-btn{
        position: absolute;
        right: 10px;
        bottom: 10px;
        border-radius: 4px;
        color: #FFFFFF !important;
      }
      .article-comment__input__cancel{
        right: 90px;
        color: #D4D4D4 !important;
      }
    }
  }
}