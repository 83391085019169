@import "src/styles/variables";

.journey {

}

.patient-table-image{
    width: 30px;
    height: 30px;
    margin-right: 8px;
    border-radius: 50%;
    object-fit: cover;
}