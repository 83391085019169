@import "src/styles/variables";

.journey-events {
  width: 90%;
  min-height: 400px;
  margin: 40px auto;
  padding-bottom: 30px;
  position: relative;

  .app-header {
    .app-header__title {
      font-size: 20px;
      color: #212121;
    }

    .app-header__action {
      display: none;
    }
  }

  .journey-events__parent {
    position: relative;

    .journey-events__row__label {
      position: absolute;
      border-radius: 8px;
      padding: 7px 18px;
      left: 0;
      right: 0;
      width: 15%;
      text-align: center;

      &.pre {
        background: #DCF9DE 0 0 no-repeat padding-box;
        color: #12C00C;
      }

      &.during {
        background: #A0C8EF37;
        color: #105ADA;
      }

      &.after {
        background: #EFE2A037;
        color: #DAA310;
      }
    }
  }

  .journey-events__row {
    margin-top: 30px;

    .journey-events__date-info,
    .journey-events__card {
      display: inline-block;
      vertical-align: middle;
    }

    .journey-events__date-info {
      position: relative;
      width: 15%;
      text-align: center;
      margin-right: 5%;

      .ant-checkbox-inner {
        border-radius: 50% !important;
        width: 28px !important;
        height: 28px !important;
        background: #5EE382 !important;
        border-color: #5EE382 !important;

        &::after {
          left: 33.5% !important;
          width: 6px !important;
          height: 11px !important;
          border-color: #FFFFFF !important;
        }
      }

      .unchecked {
        .ant-checkbox-inner {
          background: transparent !important;
          border-color: inherit !important;
        }
      }

      .journey-events__date {
        font-size: 16px;
        color: #000000;
        font-weight: bold;
      }

      .journey-events__day {
        font-size: 14px;
        color: #888585;
      }
    }

    .journey-events__card {
      width: 80%;
      border-radius: 8px;
      padding: 34px;
      box-shadow: 0 3px 12px #0000000A;
      cursor: pointer;

      &.completed {
        background: #15AA0F05 0% 0% no-repeat padding-box;
        border: 1px dashed #15AA0F;
      }

      .journey-events__card__title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;

        .journey-event__visit {
          margin-left: 10px;
        }
      }

      .journey-events__card__description {
        margin-top: 10px;
        font-size: 16px;
        color: #646464;
        width: 90%;

        img,
        figure {
          max-width: 100%;
        }
      }

      .journey-events__card__staff {
        display: inline;
        margin: 15px 5px 5px;

        .dp-chip span {
          cursor: pointer;
        }

        .journey-events__card__staff__name {
          color: #000000;
          font-weight: 600;
        }
      }
    }
  }

  .spinner-loader {
    bottom: 25%;
    left: 55%;
  }
}

.journey-event__visit {
  display: inline-block;
  background: #ECECEC 0% 0% no-repeat padding-box;
  border-radius: 13px;
  padding: 5px 10px;
  color: #000000;
  font-size: 12px;
  font-weight: normal;
  vertical-align: middle;
}

.ant-collapse.journey-events__collapse {
  border: none;
  background-color: transparent;

  >.ant-collapse-item {
    padding: 8px;
    border: none;
    background-color: #FFFFFF;
    box-shadow: 8px 8px 40px #00000010;
    margin-bottom: 1rem;

    >.ant-collapse-header {
      font-size: 18px;
      color: $secondary-color;
      font-weight: bold;
    }

    .ant-collapse-content {
      border: none;
    }
  }


}