@import "src/styles/variables";

.tag-header{
    display: flex;
    justify-content: flex-end;
    .tag-header__button{
        height: 42px;
        border-radius: 8px;
        font-size: 14px;
        background: $primary-header-button-gradient !important;
      }
}

.tagManager{
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .anticon{
        margin: 5px;
        font-size: 16px;
    }
    
.tag-container{
    
    width:30%;
    margin-right: 5px;

    .ant-tag {
        font-size: 16px;
        padding: 10px;
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: space-between;
        vertical-align: text-top;
        margin: 5px;

        &:hover {
            &.ant-tag-checkable{
                color:#272363;
                border: 1px #272363 solid;
                .tag-actions{
                    color:#272363;
                    display: block;
                }
            }
            &.ant-tag-checkable-checked{
                color:#f4f4f4;
                .tag-actions{
                    color:#f4f4f4;
                    display: block;
                }
            }
            
            

        }

        &.ant-tag-checkable{

            .tag-actions{
                display: none;
            }
        }
        &.ant-tag-checkable-checked{
            background-color: #272363;
        }
        
}
    
}
}