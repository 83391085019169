@import "src/styles/variables";

.patient-journey-card {
  width: 350px;
  height: 250px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EEEEEE;
  border-radius: 15px;
  padding: 15px 20px;
  position: relative;
  // margin-bottom: 15px;
  cursor: pointer;
  display: inline-flex;

  &--value,
  &--title {
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  &--title {
    font-size: 14px;
    color: #888585;
  }

  &--value {
    font-size: 16px;

    &.text-bold {
      font-size: 18px;
    }
  }

  .card-completed {
    position: absolute;
    display: inline-block;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .patient-journey-card__header {
    text-align: right;
    margin-bottom: 5px;

    .patient-journey-card__status {
      border-radius: 13px;
      padding: 4px 12px;
      font-size: 12px;
      display: inline-block;
      margin-right: 10px;
      text-align: center;
      position: absolute;
      top: .75rem;
      right: 0.25rem;

      &.ongoing,
      &.completed {
        i {
          vertical-align: middle;
          margin-right: 5px;
        }
      }

      &.ongoing {
        background: $ongoing-background;
        color: $ongoing-font-color;

        .icon-on-going:before {
          color: $ongoing-font-color;
        }
      }

      &.draft {
        margin-left: .1rem;
        color: #FF5C5C;
        background-color: #FFE5E5;

        .icon-pending:before {
          font-size: 10px;
          color: #FF5C5C;
        }
      }

      &.onhold {
        background: rgba(239, 226, 160, 0.2156862745);
        color: #DAA310;

        .icon-accepted:before {
          color: #DAA310;
        }
      }

      &.completed {
        background: $completed-background;
        color: $completed-font-color;

        .icon-accepted:before {
          color: $completed-font-color;
        }
      }
    }

    .anticon-ellipsis {
      font-size: 20px !important;
      vertical-align: middle;
    }
  }

  .patient-journey-card__row {
    margin-bottom: 8px;

    span.patient-journey-card__img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }

    .patient-journey-card__row-content {
      margin-left: 15px;
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      width: calc(100% - 80px);
    }

    .patient-journey-card__name {
      font-size: 18px;
      font-weight: bold;
      color: #212121;
    }

    .patient-journey-card__row-icon {
      display: inline-block;
      vertical-align: middle;
      background: #F4F4F4;
      height: 50px;
      width: 50px;
      text-align: center;
      line-height: 52px;
      border-radius: 50%;

      .icon-surgery {
        font-size: 15px;
      }

      .icon-date {
        font-size: 16px;
      }
    }
  }
}