@import "src/styles/variables";

.staffs-and-patients {
  position: relative;
  .ant-tabs-nav:before{
    border: unset !important;
  }
  .ant-tabs-ink-bar{
    display: none !important;
  }

  .ant-tabs-tab{
    padding: 6px 20px !important;
    .ant-tabs-tab-btn{
      color: #646464 !important;
    }
    &:hover{
      .ant-tabs-tab-btn{
        color: $secondary-color !important;
      }
    }
  }
  .ant-tabs-tab-active{
    border: 1px solid $secondary-color;
    border-radius: 12px;
    .ant-tabs-tab-btn{
      color: $secondary-color !important;
    }
  }

  .staffs-and-patients__action{
    position: absolute;
    top:0;
    right: 1%;
    z-index: 1;
    .staffs-and-patients__add, .search-component{
      display: inline;
    }
    .staffs-and-patients__add{
      height: auto !important;
      font-size: 14px !important;
    }
  }
}