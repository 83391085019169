@import "variables";
@import "helpers";
@import "icons";
@import "antOverride";

/* General component styles */
body {
  background-color: #FFFFFF;
  color: $primary-black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
  font-family: 'Nunito', sans-serif !important;
}

.app-wrapper {
  padding: 3rem 0 0 20%;
  width: 95%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}