@import "src/styles/variables";

.journey-template-carousel-form{
  margin-top: 15px;
  .journey-template-carousel-form__images{
    .carousel__add-image{
      width: 150px;
      height: 125px;
      background-color: #DEEFFE;
      text-align: center;
      line-height: 125px;
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 15px;
      .journey-template-carousels__add{
        background: #DEEFFE !important;
        font-size: 30px;
        border-radius: 50%;
        border: 1px dashed #406481;
        width: 50px;
        height: 50px;
        color: #406481 !important;
        padding: 0 !important;
        vertical-align: middle;
        display: inline-block;
      }
      .journey-template-carousels__image-input{
        height: 125px;
        width: 125px;
        z-index: 1;
        position: absolute;
        opacity: 0;
        left: 0;
        cursor: pointer;
        display: inline-block;
      }
    }
    .journey-template-carousel-form__image{
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 150px;
      margin-right: 15px;
      margin-bottom: 10px;
      img{
        width: 100%;
        height: 125px;
        object-fit: cover;
      }
    }
    .carousel-image-remove{
      position: absolute;
      right: 5px;
      top:5px;
      width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      color: $close-color;
      background: #FFFFFF;
      border-radius: 50%;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .journey-template-carousel-form__field{
    margin-top: 15px;
    width: 75%;
    .journey-template-carousel-form__description{

    }
  }
}