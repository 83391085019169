@import "src/styles/variables";

.patients {}

.patients__journey-list {
  margin: 45px 0 0 0;
  padding-bottom: 1rem;

  .patients__journey-list__column {
    display: inline-block;
    vertical-align: top;
    width: 37.5%;
    min-height: 250px;
    margin-right: 5%;

    &:last-child {
      margin-right: 0;
    }

    .patients__journey-list__title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
}