
.journey-template-step-entity {
    margin-top: 10px;
    .journey-template-step-entity__label {
        font-size: 14px;
        color: #888585;
        margin-bottom: 5px;
    }
    .dropdown-field .ant-select.ant-select-multiple.ant-select-show-search .ant-select-selector {
        height: auto;
    }
}

.journey-template-step-entity__option {
    .journey-template-step-entity__title {
        margin-bottom: 5px;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .journey-template-step-entity__author-label {
        color: #888585;
    }
    .journey-template-step-entity__title,
    .journey-template-step-entity__created-date {
        display: inline-block;
        vertical-align: bottom;
    }
}

.journey-template-step-entity__deselect {
    padding: 10px;
    cursor: pointer;
}