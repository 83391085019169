@import "src/styles/variables";

.staff-basic-details__modal {

  .ant-modal-content,
  .ant-modal-body {
    padding: 0 !important;
  }

  .staff-basic-details__row {
    position: relative;
    padding: 30px 40px;
    background: url("../../../assets/header.png") no-repeat center;
    background-size: cover;
    color: #FFFFFF;
    border-radius: 10px;
  }

  .hide-border-radius {
    border-radius: 10px 0 0 0;
  }

  .staff-basic-details__column {
    display: inline-block;
    vertical-align: middle;
  }

  .image-column {
    // width: 30%;
    margin-right: 20px;

    img {
      width: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  .staff-basic-details__name {
    font-size: 18px;
    font-weight: bold;
  }

  .staff-basic-details__speciality {
    font-size: 16px;
    font-weight: lighter;
  }

  .staff-basic-details__email,
  .staff-basic-details__phone {
    font-size: 14px;
    font-weight: lighter;
  }

  .staff-basic-details__email {
    cursor: pointer;
  }

  .staff-basic-details__action {
    position: absolute;
    bottom: 15px;
    right: 20px;
    font-size: 13px;
    font-weight: lighter;
    cursor: pointer;

  }

  .staff-basic-details__form {
    opacity: 0;
    display: none;
    height: 0;
    max-height: 0;
    transition: max-height 250ms linear, height 250ms linear, opacity 100ms linear,display 100ms linear;

    .staff-basic-details__password {
      padding: 30px 40px;
      // height: 0;
    }

    .auth-form__header {
      color: $primary-password-reset-color;
      font-size: 18px;
    }

    .staff-basic-details__form-actions {
      text-align: right;

      .auth-form__action {
        display: inline-block;
        vertical-align: top;
        width: 30% !important;
        font-size: 15px !important;

        &.ant-btn-primary {
          background: $primary-password-reset-color !important;
          border-radius: 4px;
        }
      }
    }
  }

  .show-form {
    display: block;
    max-height: 500px;
    height: 500px;
    opacity: 1;
    transition: max-height 250ms linear, opacity 150ms linear;
  }
}