.journey-patient-card {
    background: rgba(255, 255, 255, 0.1490196078) 0 0 no-repeat padding-box;
    border-radius: 12px;
    display: inline-block;
    width: fit-content;
    max-width: 400px;
    padding: 8px 15px 8px 8px;
    vertical-align: top;
    align-items: center;
    font-size: 12px;
    gap: 5px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap !important;
    cursor: pointer;
    overflow: hidden;
  
  
    .journey-patient-card--title {
        width: fit-content;
        max-width: 350px;
        text-overflow: ellipsis;
        font-size: 15px;
        white-space: nowrap;
        overflow: hidden;
    }
  
    .journey-patient-card--title,
    .journey-patient-card--dob,
    .journey-patient-card--age,
    .journey-patient-card--mobile {
      margin: 0 0 0 0;
    }
  }