@import "src/styles/variables";

.rich-text-viewer {

  //   .ck-editor__top{
  //     display: none !important;
  //   }
  //   .ck-editor__editable{
  //     border: unset !important;
  //   }
  &.borderless {
    .tox-tinymce {
      border: none !important;
    }
  }

  .tox {


    .tox-statusbar,
    .tox-editor-header {
      display: none;
    }

    // .tox-sidebar-wrap {
    //   display: block !important;
    //   height: 100% !important;

    //   .tox-edit-area {
    //     height: fit-content;

    //     iframe {
    //       position: static;
    //       height: 100%;
    //     }
    //   }
    // }

  }

  .tox-edit-area__iframe {
    img {
      width: 100%;
    }
  }
}