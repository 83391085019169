@import "src/styles/variables";

.journey-template-members {
  .journey-template-members__list {
    margin: .5rem 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-width: 400px;
    // height: 65px;
  }

  .remove-icon {
    position: absolute;
    top: -5px;
    right: 10px;
    color: $danger-color;
    cursor: pointer;
  }

  .journey-template-members__image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: contain;
  }

  .journey-template-members__add {
    background: #DEEFFE !important;
    font-size: 30px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: #406481 !important;
    padding: 0 !important;
    vertical-align: middle;
    display: block;
  }
}

.journey-template-members__select {
  .ant-select {
    .ant-select-selector {
      max-height: 100px;
      overflow-y: scroll;
    }
  }

  .ant-select-multiple {
    width: 300px !important;
  }

  .ant-select-selection-item {
    height: auto !important;
  }
}

.member-option {

  .member-image,
  .member-info {
    display: inline-block;
    vertical-align: middle;
  }

  .member-image {
    width: 25%;
    margin-right: 5%;
    border-radius: 50%;

    img {
      width: 100%;
    }
  }

  .member-info {
    width: 70%;

    .member-name {
      font-size: 15px;
      color: #4F4F4F;
    }

    .member-speciality {
      font-size: 13px;
      color: #646464;
    }
  }
}