.journey-faq {
    display: flex;

    .journey-faq__categories {
        min-height: 400px;
        flex: 1;
        flex-direction: column;
        padding: 10px 20px 10px 0;
        box-shadow: 0px 10px 20px #AEAEAE29;

        &.no-shadow {
            box-shadow: none;
        }

        .journey-faq__category-tile {
            padding: 10px 1.5rem;
            margin-bottom: 15px;
            border-radius: 4px;
            border-bottom: 1px solid #E8E8E8;
            cursor: pointer;

            &.active {
                background-color: #234763;
                color: #FFF;
            }
        }
    }

    .journey-faq__questions {
        margin-top: 10px;
        flex: 3;
        flex-direction: column;

        .ant-collapse {
            border: none;
            background-color: #FFF;

            .ant-collapse-header {
                font-weight: bold;
                font-size: 18px;
            }

            .ant-collapse-content {
                border: none;
            }

            >.ant-collapse-item {
                border: none;
                box-shadow: 0px 10px 20px #AEAEAE29;
                margin: 0 0 1.5rem 1rem;
                padding: 7px 10px;
                border-radius: 4px;
            }
        }
    }

    .journey-faq__question-tags {
        >.journey-faq__question-tag-item {
            padding: 5px 15px;
            margin-right: 10px;
            display: inline-block;
            border-radius: 50px;

            &.type-1 {
                color: #1046C5;
                background: #DCE5F9;
            }

            &.type-2 {
                color: #1BBF31;
                background: #DCF9E0;
            }

            &.type-3 {
                color: #601ADA;
                background: #E7DCF9;
            }
        }
    }

    .journey-faq__actions {
        margin-top: 20px;
        text-align: right;

        i {
            background: #EFEFEF;
            border-radius: 4px;
            margin-right: 10px;
            padding: 8px;
            cursor: pointer;

            &:before {
                color: #000000;
            }
        }
    }
}