.journey-status-form {
    .journey-status-form__title {
        // text-align: center;
        font-weight: bold;
        font-size: 1.2rem;
    }

    .journey-status-form__form-actions {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        >button {
            &.ant-btn-primary {
                height: 32px;
                font-size: 14px;
            }

            &:first-child {
                margin-right: 10px;
            }
        }
    }

    .dropdown-field {
        width: 100%;
        margin: 2rem 0;

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 40px;
            padding: 3px 10px;
        }
    }
}