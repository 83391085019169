@import "src/styles/variables";

.rich-text-field {}

.tox .tox-statusbar {
    border-top: none !important;
}

.tox-statusbar__text-container {
    visibility: hidden;
    // .tox-statusbar__branding,
    // .tox-statusbar__path {
    //     display: none !important;
    // }
}