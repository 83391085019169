
.journey-template-step-entity {
    margin-top: 10px;
    .journey-template-step-entity__label {
        font-size: 14px;
        color: #888585;
        margin-bottom: 5px;
    }
    .ant-tag{
        font-size: 16px;
        padding: 8px;
        border-radius: 5px;
    }
}
