@import "src/styles/variables";

.journey-step-form {
  padding-top: 0 !important;

  .journey-template-step__title {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    line-height: 1.5;
  }

  .journey-template-step__description {
    font-size: 14px;
    color: #646464;
    margin-bottom: 10px;
  }

  // margin-top: 40px;
  .journey-step-form__header {
    padding: 2rem 5% 1.5rem 0;
    font-weight: bold;
    font-size: 16px;
    color: $primary-color;
    position: fixed;
    width: calc(100% - 20%);
    border-radius: 8px;
    top: 0;
    background: white;
    z-index: 5;

    .journey-step-form__title {
      display: inline-flex;
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .go-back {
        cursor: pointer;
      }
    }

    .form-step-info {
      display: inline-block;
      vertical-align: middle;
      color: #646464;
      font-weight: normal;
      width: 20%;
      text-align: right;
    }
  }

  .journey-step-form__journey-status {
    background: rgba(160, 200, 239, 0.2156862745);
    color: #105ADA;
    text-transform: capitalize;
    font-size: 14px;
    border-radius: 8px;
    border: unset;
    padding: 4px 16px;
    font-weight: normal;
    margin-left: 10px;
    height: 50%;
    &.draft {
      background: #EFE2A037;
      color: #DAA310;
    }

    &.green,
    &.completed,
    &.resolved {
      background: #A2EFA037;
      color: #12A40D;
    }

    &.red {
      background: #EFA0A037;
      color: #C50F0F;
    }

    &.active {
      background: #A0C8EF37;
      color: #105ADA;
    }

    &.onhold,
    &.pending {
      background: #EFE2A037;
      color: #DAA310;
    }
  }

  .journey-step-form__steps__actions {
    text-align: right;
    // padding: 2rem 0;
  }

  .journey-step-form__loading {
    position: relative;
    min-height: 500px;

    .spinner-loader {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.ant-dropdown-menu {
  &.secondary-button-dropdown {
    padding: 0 !important;

    .ant-dropdown-menu-item {
      padding: 0;
    }
  }

}