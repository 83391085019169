/* Color palette */
$primary-color: #514B8F;
$secondary-color: #272363;
$light-white: #FFFFFF40;
$primary-light-color: #0D494E10;
$primary-black: #4B4B4B;
$dark-blue: #22509D;
$light-grey: #00000015;
$dark-grey: #8898AA;
$success-color: green;
$danger-color: #FE0000;
$warning-color: #F3B21B;
$body-bg: #F5F8FA;
$table-bg: #F7FAFC;
$input-bg: #F8FBFC;
$sidebar-profile-bg:#F0F0F4;
$close-color: #F74A4A;
$completed-background: #C5FFDE;
$ongoing-background: #C5EFFF;
$completed-font-color: #16C561;
$ongoing-font-color: #0D96CA;
$communication-icon-bg: #FFFFFF07;
$primary-password-reset-color: #234763;


/* Shadows */
$primary-shadow: 10px 20px 40px #AEAEAE24;
$secondary-shadow: 0px 3px 6px #2C28281C;

/* Gradients */
$primary-gradient: transparent linear-gradient(114deg, #272363 0%, #514B8F 100%) 0% 0% no-repeat padding-box;
$primary-button-gradient: transparent linear-gradient(112deg, #272363 0%, #514B8F 100%) 0 0 no-repeat padding-box !important;
$primary-header-button-gradient: transparent linear-gradient(119deg, #272363 0%, #514B8F 100%) 0% 0% no-repeat padding-box;

/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;

/* Border */
$form-input-border-color: #d9d9d9;