.journey-articles {
    min-height: 400px;

    .spinner-loader {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .journey-articles__article-item {
        cursor: pointer;
        padding: 1.5rem 0;
        border-bottom: 1px solid #E8E8E8;
        display: flex;

        .journey-articles__article-cover {
            flex: 1;
            flex-direction: column;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .journey-articles__article-item-author,
        .journey-articles__article-item-description {
            margin-top: 10px;
        }

        .journey-articles__article-item-description {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            // max-height: 100px;
        }

        .journey-articles__article-item-info {
            // width: 80%;
            padding-left: 1rem;
            flex: 3;
            flex-direction: column;
        }

        .journey-articles__article-item-title {
            font-weight: bold;
            font-size: 18px;
        }

        .journey-articles__article-item-author-info {
            min-width: 200px;
            margin-right: 15px;
            display: inline-block;

            label {
                color: #C3C3C3;
            }

            img {
                width: 45px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
}