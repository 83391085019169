@import "src/styles/variables";

.journey-doctor-notes {
  padding-top: 40px;
  min-height: 400px;

  .journey-doctor-notes__label {
    color: #888585;
    font-size: 15px;
    margin-bottom: 20px;
  }

  .journey-doctor-notes__description {
    font-size: 18px;
    color: #4F4F4F;
  }

  .journey-doctor-notes__list {
    margin-top: 18px;
    font-size: 18px;
    padding-inline-start: 20px;
    color: #4F4F4F
  }
}