@import "src/styles/variables";

.journey-template-steps {
  padding-top: 7rem;

  .journey-template-steps__parent {
    width: 27%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 10px 20px #AEAEAE29;
    border-radius: 8px;
    padding: 15px 0;
    min-height: 70vh;
    margin-right: 2%;
    margin-bottom: 1.5rem;
    display: inline-block;

    .journey-template-step {
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 20px;
      cursor: pointer;

      .journey-template-step__name {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        line-height: 1.5;
      }

      .journey-template-step__days {
        font-size: 14px;
        color: #646464;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        border-bottom: 1px solid #F0F0F0;
      }
    }

    .journey-template-steps__action {
      font-size: 18px;
      color: #235663;
      margin-bottom: 30px;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .journey-template-steps__child {
    display: inline-block;
    width: 71%;
    vertical-align: top;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 10px 20px #AEAEAE29;
    border-radius: 8px;
    padding: 30px 35px;
    margin-bottom: 3rem;
  }
}

.journey-template-step__remove-icon {
  position: absolute;
  height: fit-content;
  top: 0;
  bottom: 0;
  margin: auto;
  color: $close-color;
  font-size: 15px;
  right: 10px;
  cursor: pointer;
}