@import "src/styles/variables";

.search-component {
  .ant-input-search{
    width: 275px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 12px #0000000A;
    border-radius: 8px;
    margin-right: 25px;
  }
  .ant-input-wrapper{
    padding: 0 10px;
  }
  .ant-input-affix-wrapper,.ant-input-group-addon .ant-btn{
    border: unset !important;
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
    box-shadow: unset !important;
  }
}