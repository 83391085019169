@import "src/styles/variables";

.attachment-viewer {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #f6f6f6;
    border-radius: 3px;
    padding: 10px;
    font-size: 12px;
    position: relative;

    .attachment-viewer__label {
      cursor: pointer;
      color: #52A3E9;
    }
    .remove-icon {
      position: absolute;
      right: -5px;
      top: -5px;
      font-size: 12px;
      color: $close-color;
      width: 15px;
      height: 15px;
      line-height: 12px;
      border-radius: 50%;
      text-align: center;
      background: #FFFFFF;
      cursor: pointer;
    }
}

.ant-modal.attachment-viewer-modal {
  .ant-modal-body {
    img {
      width: 100%;
    }
    iframe {
      width: 100%;
      height: 600px;
    }
  }
}