@import "src/styles/variables";

.journey-template-checklist-form {
  .journey-template-checklist-form__add {
    background: #F2F2F2 !important;
    border-radius: 3px;
    color: #4F4F4F !important;
  }

  .journey-template-checklist-form__field {
    position: relative;
    margin-bottom: 10px;
    width: 70%;

    .journey-template-checklist-form__input {
      width: 85%;
      height: 100%;
    }

    .journey-template-step__remove-icon {
      top: 10px !important;
    }

    .journey-template-step__drag-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 15px;
      right: 35px;
      cursor: pointer;
      padding-top: 10px;
    }
  }

  .journey-template-step__checklist-title {
    margin: 1rem 0;

    .journey-template-step__label,
    .journey-template-step__checklist-switch {
      display: inline-block;
      vertical-align: middle;
    }

    .journey-template-step__label {
      width: 40%;
    }

    .journey-template-step__checklist-switch {
      width: 60%;
      text-align: right;

      button {
        margin-left: 5px;

        &.ant-switch {
          min-width: 30px;
          height: 18px;
        }

        &.ant-switch-checked {
          background-color: #DAA310;

          .ant-switch-handle {
            left: calc(100% - 14px - 2px);
          }
        }

        .ant-switch-handle {
          width: 14px;
          height: 14px;
        }
      }

      >span {
        margin-right: 10px;
      }
    }
  }
}