@import "src/styles/variables";

.journey-form {
  .journey-basic-form {
    padding-top: 5rem;

    .hospital-form__header {
      .form-step-info {
        float: right;
        color: #646464;
        font-weight: normal;
      }
    }

    .hospital-form__actions {
      width: 110%;
      margin-top: 40px;

      .hospital-form__action {
        width: 10%;
      }
    }
    .hospital-form__field{
      .dropdown-field .ant-select.ant-select-multiple.ant-select-show-search .ant-select-selector{
        height: auto;
        min-height: 52px;
      }
    }
  }
}

.journey-basic-form__name-wrapper {

  .journey-basic-form__name,
  .journey-basic-form__phone {
    display: inline-block;
    vertical-align: middle;
  }

  .journey-basic-form__phone {
    color: #646464;
    float: right;
  }

  .journey-basic-form__email {
    text-transform: none;
  }
}