@import "./../../../styles/variables";

.phone-number-input {

    .phone-number-input-field {
        display: flex;
        align-items: center;
    }

    label {
        display: block;
        margin-bottom: .25rem;
    }

    .PhoneInput {
        display: flex;
        overflow: hidden;

        .PhoneInputCountry {
            .PhoneInputCountryIconUnicode {
                font-size: 22px;
            }

        }

        input {
            border: 0;
            outline: 0;
            height: 100%;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }
}