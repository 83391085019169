@import "src/styles/variables";

.spinner-loader {
  position: absolute;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  box-shadow: 0 2px 4px #0000001a;
  text-align: center;
  line-height: 40px;
  z-index: 100;
}