@import "src/styles/variables";

.delete-confirmation {
  .delete-confirmation__header{
    font-size: 18px;
    font-weight: 700;
    color: $secondary-color;
  }

  .delete-confirmation__content{
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
  }

  .delete-confirmation__actions{
    text-align: center;
    margin-top: 15px;
  }

  .delete-confirmation__action{
    margin-right: 10px;
    &.ant-btn-primary{
      background: $close-color !important;
    }
  }
}