@import "src/styles/variables";

.journey-template-carousels {
  padding: 0 30px;

  .journey-template-carousels__image {
    height: 125px !important;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .journey-template-carousels__description {
    background: #F6F6F6;
    border-radius: 3px;
    margin: 15px 0;
    width: 100%;
    color: #4F4F4F;
    padding: 20px;
  }

  .journey-template-carousels__fullscreen {
    float: right;
    z-index: 1;
  }

  .journey-template-carousels__image-thumbnail {
    max-height: 125px;
    object-fit: cover;
  }
}

.journey-template-carousels__navigator {
  width: 20px;
  height: 20px;
  border-left: 2px solid #000000;
  border-bottom: 2px solid #000000;

  &.left {
    transform: rotate(45deg);
  }

  &.right {
    transform: rotate(-135deg);
  }
}