@import "src/styles/variables";

.patient-header {
  background: url("../../../assets/header2.png") no-repeat;
  background-size: cover;
  border-radius: 12px;
  color: #FFFFFF;
  padding: 40px 50px 20px 50px;
  position: relative;

  .patient-header__title {

    .go-back,
    .patient-header__patient-details {
      color: #FFFFFF;
      display: inline-block;
    }

    .go-back {
      width: 3%;
      cursor: pointer;
    }

    .patient-header__patient-details {
      width: 90%;
      margin-left: 2%;

      .patient-header__image {
        display: inline-block;
        width: 7.5%;
        // img{
        //   width: 100%;
        //   border-radius: 50%;
        //   height: 65px;
        // }
      }

      .patient-header__basic-info {
        position: relative;
        display: inline-block;
        width: 90%;
        margin-left: 12px;
        vertical-align: middle;

        .patient-header__name {
          font-weight: bold;
          font-size: 18px;

          .patient-header__view-more {
            font-size: 12px;
            font-weight: normal;
            margin-left: 10px;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .patient-header__age {
          font-size: 16px;
        }

      }


    }
  }

  .patient-header__edit {
    position: absolute;
    display: inline;
    background: #FFFFFF;
    border-radius: 50%;
    bottom: 12px;
    right: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;

    i:before {
      color: #000;
    }
  }

  .patient-header__communication,
  .patient-header__dependents {
    margin-left: 12.5%;
    margin-top: 15px;
  }

  .patient-header__dependents {
    cursor: pointer;

    .patient-header__dependents__title {
      margin-bottom: 8px;
    }

    .patient-header__dependent {
      display: inline-block;
      vertical-align: top;
      background: #FFFFFF26 0 0 no-repeat padding-box;
      border-radius: 12px;
      width: 19%;
      margin-right: 1.25%;
      margin-bottom: 10px;
      padding: 10px;

      &:nth-child(5n) {
        margin-right: 0;
      }
    }

    .patient-header__dependent__image,
    .patient-header__dependent__basic-info {
      display: inline-block;
      width: 30%;
    }

    .patient-header__dependent__image {
      img {
        width: 100%;
        border-radius: 50%;
        height: 45px;
      }
    }

    .patient-header__dependent__basic-info {
      width: 65%;
      margin-left: 5%;
      vertical-align: middle;
      font-size: 14px;
    }
  }
}