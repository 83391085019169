.faq-category-form {
    h2 {
        font-weight: bold;
    }

    .form-actions {
        .ant-btn {
            height: 42px;
            border-radius: 8px;
        }
    }
}