.journey-category-steps {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;

    li {
        padding: 10px 10px 10px 32px;
        cursor: pointer;

        .icon-delete {
            visibility: hidden;
            padding: .5rem;
            border-radius: 100px;

            &::before {
                color: rgb(195, 0, 0);
            }
        }

        &:hover {
            background-color: #F1F0F8;

            .icon-delete {
                visibility: visible;
            }
        }

        &.journey-category-steps__active {
            background-color: #F1F0F8;
        }
    }
}